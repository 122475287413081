import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import DeliveryContent from "../TermsContent/DeliveryContent";
import TermsBanner from "../Common/TermsBanner/TermsBanner";

const DeliveryPolicy = ({ title }) => {
  useEffect(() => {
    if (window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);
  
  return (
    <>
      <Helmet>
        <title>{title || "Delivery Policy"}</title>
      </Helmet>
      <TermsBanner heading={"Delivery Policy"} />
      <DeliveryContent />
    </>
  );
};

export default DeliveryPolicy;
