import React, { useContext } from "react";
import orderProductImg from "../../Images/orderProductImg.webp";
import OrderedTimeline from "./OrderedTimeline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faCancel,
  faClose,
  faCross,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import withReactContent from "sweetalert2-react-content";
import { environmentVar } from "../../config/environmentVar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { CartDetailContext } from "../../context/CartDetailContext";
import { Loader5 } from "../Common/Loader/Loader";
const MySwal = withReactContent(Swal);

const OrderedDetailTimeline = ({ responseData }) => {
  const navigate = useNavigate();

  const { scroll, setScroll } = useContext(CartDetailContext);
  // const cancelOrder = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: "No",
  //     confirmButtonText: "Yes, Cancel it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let data = {
  //         order_id: responseData?.order_id,
  //       };

  //       let config = {
  //         method: "put",
  //         url: `${environmentVar?.apiUrl}/api/order/cancel_order`,
  //         withCredentials: true,
  //         data: data,
  //       };

  //       axios
  //         .request(config)
  //         .then((response) => {
  //           // setUpdateState(!updateState);
  //           toast.success("Order cancelled Successfully", {
  //             autoClose: 2000,
  //           });
  //           navigate("/orderhistory");
  //         })
  //         .catch((error) => {
  //           toast.error(error?.response?.data?.message || error?.message, {
  //             autoClose: 2000,
  //           });
  //         });
  //     }
  //   });
  // };
  const formatDate = (inputDate) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Parse the input date string
    const date = new Date(inputDate);

    // Extract the day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(2);

    // Determine the suffix for the day
    let suffix;
    if (day >= 11 && day <= 13) {
      suffix = "th";
    } else {
      switch (day % 10) {
        case 1:
          suffix = "st";
          break;
        case 2:
          suffix = "nd";
          break;
        case 3:
          suffix = "rd";
          break;
        default:
          suffix = "th";
          break;
      }
    }

    // Format the date string
    const formattedDate = `${date
      .toDateString()
      .slice(0, 3)}, ${day}${suffix} ${month} ${year}`;

    return formattedDate;
  };

  const formatDateWithTime = (inputDate) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Parse the input date string
    const date = new Date(inputDate);

    // Extract the day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(2);

    // Extract the time
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Determine the suffix for the day
    let suffix;
    if (day >= 11 && day <= 13) {
      suffix = "th";
    } else {
      switch (day % 10) {
        case 1:
          suffix = "st";
          break;
        case 2:
          suffix = "nd";
          break;
        case 3:
          suffix = "rd";
          break;
        default:
          suffix = "th";
          break;
      }
    }

    // Format the date and time string
    const formattedDate = `${date
      .toDateString()
      .slice(0, 3)}, ${day}${suffix} ${month} ${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return `${formattedDate} , ${formattedTime}`;
  };

  const cancelOrder = () => {
    const validationSchema = Yup.object({
      message: Yup.string()
        .min(50, "Message must be at least 50 characters")
        .required("Message is required"),
    });

    MySwal.fire({
      title: <div class="swal2-title-custom">Cancel Order</div>,
      html: (
        <div>
          <div className="swal2-divider"></div>
          <div className="swal2-subtitle">
            Do you want to send the order cancellation request?
          </div>
          <Formik
            initialValues={{ message: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              let data = {
                order_id: responseData?.order_id,
                message: values.message,
              };

              let config = {
                method: "put",
                url: `${environmentVar?.apiUrl}/api/order/cancel_order`,
                withCredentials: true,
                data: data,
              };
              console.log(data);

              axios
                .request(config)
                .then((response) => {
                  // setUpdateState(!updateState);
                  toast.success("Order cancelled Successfully", {
                    autoClose: 2000,
                  });
                  navigate("/orderhistory");
                })
                .catch((error) => {
                  console.log(error);
                  toast.error(
                    error?.response?.data?.message || error?.message,
                    {
                      autoClose: 2000,
                    }
                  );
                })
                .finally(() => {
                  setSubmitting(false);
                  MySwal.close();
                });
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <div>
                  <div className="cancel_message_wrapper">
                    <label htmlFor="message">Reason For Cancellation</label>
                    <Field name="message" as="textarea" rows="4" />
                  </div>
                  <ErrorMessage
                    name="message"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "15px",
                      textAlign: "left",
                    }}
                  />
                </div>
                <div className="swal2-actions">
                  <button
                    type="submit"
                    className="swal2-confirm swal2-styled"
                    style={{ backgroundColor: "#3085d6", color: "white" }}
                    disabled={!isValid || isSubmitting}
                  >
                    Yes, Cancel it!
                  </button>
                  <button
                    type="button"
                    className="swal2-cancel swal2-styled"
                    style={{ backgroundColor: "#d33", color: "white" }}
                    onClick={() => MySwal.close()}
                  >
                    No
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ),
      showConfirmButton: false,
      showCancelButton: false,
    });
  };

  // console.log("respooonnnn", responseData?.status);
  return (
    <>
      {!responseData ? (
        <Loader5 />
      ) : (
        <div className="ordereddeailtimeline-main">
          <div className="ordered-product-details-main">
            <div className="ordered-product-details-img">
              <img
                src={`${environmentVar?.cdnUrl}/uploads/${responseData?.variant_quantity[0]?.thumbnail_url}`}
              />
            </div>
            <div className="ordered-product-details">
              <h2>{responseData?.variant_quantity[0]?.variant_name}</h2>
              {/* <h3>Color: Black</h3> */}
              <h4>
                {responseData?.country_code == "IN"
                  ? "₹"
                  : responseData?.country_code == "AE"
                  ? "د.إ"
                  : responseData?.country_code == "US"
                  ? "$"
                  : "₹"}{" "}
                {
                  responseData?.is_student_info_id
                    ? responseData?.card_data == null
                      ? (
                          Number(responseData?.sub_total) +
                          Number(responseData?.delivery_charges)
                        )?.toFixed(2)
                      : responseData?.variant_quantity?.length > 1
                      ? (
                          Number(responseData?.sub_total) +
                          Number(responseData?.delivery_charges)
                        )?.toFixed(2)
                      : (
                          Number(responseData?.delivery_charges) -
                          Number(responseData?.sub_total)
                        )?.toFixed(2)
                    : (
                        Number(responseData?.sub_total) +
                        Number(responseData?.delivery_charges)
                      )?.toFixed(2)
                  // responseData?.card_data == null ?
                  // (Number(responseData?.sub_total) +
                  //   Number(responseData?.delivery_charges)
                  // )?.toFixed(2):0
                }{" "}
                <span>
                  {responseData?.coupon_id ? "1 Offer Applied" : <></>}
                </span>
              </h4>
              {responseData?.status == "cancelled" ? (
                <span style={{ color: "red", fontWeight: 600 }}>
                  Order Cancelled
                </span>
              ) : (
                responseData?.status == "refunded" && (
                  <span style={{ color: "#20b320", fontWeight: 600 }}>
                    Amount Refunded
                  </span>
                )
              )}
              {responseData?.status == "cancelled" ? (
                <span style={{ fontWeight: 500 }}>
                  Refund Expected By {formatDate(responseData?.refund_date)}
                </span>
              ) : (
                responseData?.status == "refunded" && (
                  <span style={{ fontWeight: 500 }}>
                    Refunded on {formatDateWithTime(responseData?.refunded_at)}
                  </span>
                )
              )}

              {/* <h5>Return policy ended on Nov 19</h5> */}
            </div>
          </div>
          {responseData?.status != "cancelled" &&
            responseData?.status != "refunded" && (
              <OrderedTimeline responseData={responseData} />
            )}
          <div className="rate-help-review">
            {responseData?.status === "delivered" ? (
              <div
                className="rate-help-review-review"
                onClick={() => {
                  navigate(
                    `/detailpage/${responseData?.variant_quantity?.[0]?.product_id}`
                  );
                  setScroll(1);
                }}
              >
                <FontAwesomeIcon
                  icon={faStar}
                  size="2x"
                  className="info-icon"
                />
                Rate & Review
              </div>
            ) : (
              <></>
            )}

            <div
              className="rate-help-review-review"
              onClick={() => navigate("/contact")}
            >
              <FontAwesomeIcon icon={faInfo} size="2x" className="info-icon" />
              Need Help?
            </div>
            {responseData?.status === "new" ? (
              <div
                className="rate-help-review-review"
                onClick={() => cancelOrder()}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  size="2x"
                  className="info-icon"
                />
                Cancel
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OrderedDetailTimeline;
